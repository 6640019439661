export default {
	v: "5.9.4",
	fr: 29.9700012207031,
	ip: 0,
	op: 120.0000048877,
	w: 1920,
	h: 1920,
	nm: "RECYcle",
	ddd: 0,
	assets: [],
	layers: [
		{
			ddd: 0,
			ind: 1,
			ty: 1,
			nm: "Black Solid 1",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: {
					a: 1,
					k: [
						{
							i: { x: [0.284], y: [0.976] },
							o: { x: [0.562], y: [0.008] },
							t: 41,
							s: [0],
						},
						{ t: 67.0000027289659, s: [360] },
					],
					ix: 10,
				},
				p: {
					a: 1,
					k: [
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.167, y: 0.167 },
							t: 0,
							s: [960.018, 2616.638, 0],
							to: [0, -275.667, 0],
							ti: [0, 275.667, 0],
						},
						{
							i: { x: 0.833, y: 0.833 },
							o: { x: 0.167, y: 0.167 },
							t: 30,
							s: [960.018, 962.638, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{
							i: { x: 0.833, y: 0.833 },
							o: { x: 0.333, y: 0 },
							t: 71,
							s: [960.018, 962.638, 0],
							to: [0, -254.5, 0],
							ti: [0, 254.5, 0],
						},
						{ t: 96.0000039101602, s: [960.018, -564.362, 0] },
					],
					ix: 2,
					l: 2,
				},
				a: { a: 0, k: [1221, 1110, 0], ix: 1, l: 2 },
				s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
			},
			ao: 0,
			hasMask: true,
			masksProperties: [
				{
					inv: false,
					mode: "a",
					pt: {
						a: 0,
						k: {
							i: [
								[0, 0],
								[10.632, 6.696],
								[0, 0],
								[0, 0],
								[-14.771, 0],
								[0, 0],
								[0, -10.362],
								[10.362, 0],
								[0, 0],
								[-23.396, 37.57],
								[0, 0],
								[0, 0],
								[-12.229, 2.819],
								[0, 0],
								[-1.175, -5.11],
							],
							o: [
								[2.82, 12.218],
								[0, 0],
								[0, 0],
								[-7.83, 12.523],
								[0, 0],
								[10.362, 0],
								[0, 10.362],
								[0, 0],
								[-44.266, 0],
								[0, 0],
								[0, 0],
								[-10.638, -6.696],
								[0, 0],
								[5.046, -1.087],
								[0, 0],
							],
							v: [
								[1176.443, 1105.127],
								[1155.238, 1120.223],
								[1140.964, 1111.294],
								[1111.066, 1159.126],
								[1127.013, 1187.897],
								[1145.822, 1187.897],
								[1164.607, 1206.67],
								[1145.822, 1225.496],
								[1127.072, 1225.496],
								[1079.217, 1139.266],
								[1109.074, 1091.44],
								[1094.765, 1082.453],
								[1099.071, 1056.725],
								[1152.777, 1044.302],
								[1164.107, 1051.38],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 1",
				},
				{
					inv: false,
					mode: "f",
					pt: {
						a: 0,
						k: {
							i: [
								[-7.166, -11.531],
								[0, 0],
								[0, 0],
								[-12.246, -2.837],
								[0, 0],
								[-1.163, 5.065],
								[0, 0],
								[10.644, -6.632],
								[0, 0],
								[0, 0],
								[22.086, -35.313],
								[0, 0],
								[-8.693, -5.474],
								[-5.486, 8.767],
								[0, 0],
							],
							o: [
								[0, 0],
								[0, 0],
								[-10.667, 6.643],
								[0, 0],
								[5.065, 1.172],
								[0, 0],
								[2.813, -12.224],
								[0, 0],
								[0, 0],
								[-22.074, -35.326],
								[0, 0],
								[-5.463, 8.723],
								[8.764, 5.497],
								[0, 0],
								[7.488, -11.977],
							],
							v: [
								[1236.886, 1008.712],
								[1261.116, 1047.562],
								[1246.872, 1056.42],
								[1251.147, 1082.13],
								[1304.834, 1094.553],
								[1316.118, 1087.499],
								[1328.477, 1033.793],
								[1307.29, 1018.662],
								[1292.981, 1027.591],
								[1268.781, 988.846],
								[1173.153, 988.81],
								[1168.807, 995.754],
								[1174.739, 1021.599],
								[1200.649, 1015.702],
								[1205.021, 1008.718],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 2",
				},
				{
					inv: false,
					mode: "f",
					pt: {
						a: 0,
						k: {
							i: [
								[23.437, 37.535],
								[0, 0],
								[8.799, -5.492],
								[-5.486, -8.77],
								[0, 0],
								[14.777, 0],
								[0, 0],
								[0, 0],
								[8.881, -8.882],
								[0, 0],
								[-3.657, -3.654],
								[0, 0],
								[0, 12.55],
								[0, 0],
								[0, 0],
							],
							o: [
								[0, 0],
								[-5.484, -8.799],
								[-8.776, 5.473],
								[0, 0],
								[7.842, 12.523],
								[0, 0],
								[0, 0],
								[0, -12.559],
								[0, 0],
								[-3.655, 3.655],
								[0, 0],
								[8.881, 8.87],
								[0, 0],
								[0, 0],
								[44.287, 0.006],
							],
							v: [
								[1362.706, 1139.313],
								[1352.726, 1123.301],
								[1326.857, 1117.303],
								[1320.877, 1143.196],
								[1330.857, 1159.144],
								[1314.915, 1187.926],
								[1258.531, 1187.926],
								[1258.545, 1171.044],
								[1234.479, 1161.076],
								[1195.47, 1200.097],
								[1195.473, 1213.39],
								[1234.488, 1252.357],
								[1258.542, 1242.383],
								[1258.528, 1225.537],
								[1314.836, 1225.537],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 3",
				},
			],
			sw: 1920,
			sh: 1920,
			sc: "#000000",
			ip: -4.00000016292334,
			op: 136.000005539394,
			st: -4.00000016292334,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 2,
			ty: 1,
			nm: "White Solid 2",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: {
					a: 1,
					k: [
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.167, y: 0.167 },
							t: 0,
							s: [960.029, 2614.578, 0],
							to: [0, -275.667, 0],
							ti: [0, 275.667, 0],
						},
						{
							i: { x: 0.833, y: 0.833 },
							o: { x: 0.167, y: 0.167 },
							t: 30,
							s: [960.029, 960.578, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{
							i: { x: 0.833, y: 0.833 },
							o: { x: 0.333, y: 0 },
							t: 71,
							s: [960.029, 960.578, 0],
							to: [0, -254.5, 0],
							ti: [0, 254.5, 0],
						},
						{ t: 96.0000039101602, s: [960.029, -566.422, 0] },
					],
					ix: 2,
					l: 2,
				},
				a: { a: 0, k: [1221, 1110, 0], ix: 1, l: 2 },
				s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
			},
			ao: 0,
			hasMask: true,
			masksProperties: [
				{
					inv: false,
					mode: "a",
					pt: {
						a: 0,
						k: {
							i: [
								[0, 0],
								[22.553, 0],
								[0, 0],
								[0, -22.553],
								[0, 0],
								[-22.553, 0],
								[0, 0],
								[0, 22.553],
							],
							o: [
								[0, -22.553],
								[0, 0],
								[-22.553, 0],
								[0, 0],
								[0, 22.553],
								[0, 0],
								[22.553, 0],
								[0, 0],
							],
							v: [
								[1481.941, 665.115],
								[1441.078, 624.252],
								[1000.863, 624.252],
								[960, 665.115],
								[960, 1553.729],
								[1000.863, 1594.592],
								[1441.078, 1594.592],
								[1481.941, 1553.729],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 1",
				},
			],
			sw: 1920,
			sh: 1920,
			sc: "#ffffff",
			ip: 0,
			op: 140.000005702317,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 3,
			ty: 1,
			nm: "White Solid 1",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: {
					a: 1,
					k: [
						{
							i: { x: [0.833], y: [1] },
							o: { x: [0.333], y: [0] },
							t: 81,
							s: [180],
						},
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.333], y: [0] },
							t: 82,
							s: [180],
						},
						{ t: 120.0000048877, s: [42] },
					],
					ix: 10,
				},
				p: { a: 0, k: [947.282, 984.417, 0], ix: 2, l: 2 },
				a: { a: 0, k: [473, 581, 0], ix: 1, l: 2 },
				s: { a: 0, k: [129, 129, 100], ix: 6, l: 2 },
			},
			ao: 0,
			hasMask: true,
			masksProperties: [
				{
					inv: false,
					mode: "a",
					pt: {
						a: 0,
						k: {
							i: [
								[0, 0],
								[0, -13.25],
								[0, 0],
								[0, 0],
								[0, 0],
								[-13.3, 0],
							],
							o: [
								[13.3, 0],
								[0, 0],
								[0, 0],
								[0, 0],
								[0, -13.25],
								[0, 0],
							],
							v: [
								[511.877, 303.688],
								[535.877, 327.688],
								[535.877, 367.688],
								[407.877, 367.688],
								[407.877, 327.688],
								[431.877, 303.688],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 1",
				},
				{
					inv: false,
					mode: "f",
					pt: {
						a: 0,
						k: {
							i: [
								[0, 14.4],
								[-14.15, 6.6],
								[0, 0],
								[-12.1, 0],
								[0, 0],
								[-11, -5.13],
								[0, 0],
								[0, -15.6],
								[11.4, -7.1],
								[0, -14.7],
								[13, -7.7],
								[0, -15.8],
								[13, -8.6],
								[0, -16.7],
								[13, -8.6],
								[0, -16.7],
								[26.5, 0],
								[0, 0],
								[0, 26.5],
								[-12.94, 8.6],
								[0, 16.7],
								[-12.94, 8.6],
								[0, 17.6],
								[-12.94, 9.5],
								[0, 17.6],
								[-11.47, 8.8],
							],
							o: [
								[0, -15.6],
								[0, 0],
								[10.98, -5.13],
								[0, 0],
								[12.1, 0],
								[0, 0],
								[13.3, 6.6],
								[0, 14.4],
								[11.5, 8.8],
								[0, 17.6],
								[13, 9.5],
								[0, 17.6],
								[13, 8.6],
								[0, 16.7],
								[13, 8.6],
								[0, 26.5],
								[0, 0],
								[-26.51, 0],
								[0, -16.7],
								[-12.94, -8.6],
								[0, -16.7],
								[-12.94, -8.6],
								[0, -15.8],
								[-12.94, -7.7],
								[0, -14.7],
								[-11.34, -7.1],
							],
							v: [
								[343.877, 455.388],
								[367.067, 418.988],
								[391.477, 407.488],
								[426.577, 399.688],
								[517.177, 399.688],
								[552.277, 407.488],
								[576.677, 418.988],
								[599.877, 455.388],
								[580.977, 489.488],
								[599.877, 526.788],
								[578.377, 566.788],
								[599.877, 606.788],
								[578.377, 647.688],
								[599.877, 687.688],
								[578.377, 727.688],
								[599.877, 767.688],
								[551.877, 815.688],
								[391.877, 815.688],
								[343.877, 767.688],
								[365.337, 727.688],
								[343.877, 687.688],
								[365.337, 647.688],
								[343.877, 606.788],
								[365.337, 566.788],
								[343.877, 526.788],
								[362.747, 489.488],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 2",
				},
				{
					inv: false,
					mode: "f",
					pt: {
						a: 0,
						k: {
							i: [
								[-8.8, 0],
								[0, 0],
								[0, 8.8],
								[8.8, 0],
								[0, 0],
								[0, -8.8],
							],
							o: [
								[0, 0],
								[8.8, 0],
								[0, -8.8],
								[0, 0],
								[-8.8, 0],
								[0, 8.8],
							],
							v: [
								[423.877, 559.688],
								[519.877, 559.688],
								[535.877, 543.688],
								[519.877, 527.688],
								[423.877, 527.688],
								[407.877, 543.688],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 3",
				},
				{
					inv: false,
					mode: "f",
					pt: {
						a: 0,
						k: {
							i: [
								[0, 0],
								[0, -8.8],
								[-8.8, 0],
								[0, 0],
								[0, 8.8],
								[8.8, 0],
							],
							o: [
								[-8.8, 0],
								[0, 8.8],
								[0, 0],
								[8.8, 0],
								[0, -8.8],
								[0, 0],
							],
							v: [
								[423.877, 655.688],
								[407.877, 671.688],
								[423.877, 687.688],
								[519.877, 687.688],
								[535.877, 671.688],
								[519.877, 655.688],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 4",
				},
			],
			sw: 1920,
			sh: 1920,
			sc: "#ffffff",
			ip: 70.0000028511585,
			op: 120.0000048877,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 4,
			ty: 1,
			nm: "White Solid 1",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: {
					a: 1,
					k: [
						{
							i: { x: [0.667], y: [1] },
							o: { x: [0.167], y: [0.167] },
							t: 0,
							s: [42],
						},
						{ t: 14.0000005702317, s: [0] },
					],
					ix: 10,
				},
				p: { a: 0, k: [947.282, 984.417, 0], ix: 2, l: 2 },
				a: { a: 0, k: [473, 581, 0], ix: 1, l: 2 },
				s: { a: 0, k: [129, 129, 100], ix: 6, l: 2 },
			},
			ao: 0,
			hasMask: true,
			masksProperties: [
				{
					inv: false,
					mode: "a",
					pt: {
						a: 0,
						k: {
							i: [
								[0, 0],
								[0, -13.25],
								[0, 0],
								[0, 0],
								[0, 0],
								[-13.3, 0],
							],
							o: [
								[13.3, 0],
								[0, 0],
								[0, 0],
								[0, 0],
								[0, -13.25],
								[0, 0],
							],
							v: [
								[511.877, 303.688],
								[535.877, 327.688],
								[535.877, 367.688],
								[407.877, 367.688],
								[407.877, 327.688],
								[431.877, 303.688],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 1",
				},
				{
					inv: false,
					mode: "f",
					pt: {
						a: 0,
						k: {
							i: [
								[0, 14.4],
								[-14.15, 6.6],
								[0, 0],
								[-12.1, 0],
								[0, 0],
								[-11, -5.13],
								[0, 0],
								[0, -15.6],
								[11.4, -7.1],
								[0, -14.7],
								[13, -7.7],
								[0, -15.8],
								[13, -8.6],
								[0, -16.7],
								[13, -8.6],
								[0, -16.7],
								[26.5, 0],
								[0, 0],
								[0, 26.5],
								[-12.94, 8.6],
								[0, 16.7],
								[-12.94, 8.6],
								[0, 17.6],
								[-12.94, 9.5],
								[0, 17.6],
								[-11.47, 8.8],
							],
							o: [
								[0, -15.6],
								[0, 0],
								[10.98, -5.13],
								[0, 0],
								[12.1, 0],
								[0, 0],
								[13.3, 6.6],
								[0, 14.4],
								[11.5, 8.8],
								[0, 17.6],
								[13, 9.5],
								[0, 17.6],
								[13, 8.6],
								[0, 16.7],
								[13, 8.6],
								[0, 26.5],
								[0, 0],
								[-26.51, 0],
								[0, -16.7],
								[-12.94, -8.6],
								[0, -16.7],
								[-12.94, -8.6],
								[0, -15.8],
								[-12.94, -7.7],
								[0, -14.7],
								[-11.34, -7.1],
							],
							v: [
								[343.877, 455.388],
								[367.067, 418.988],
								[391.477, 407.488],
								[426.577, 399.688],
								[517.177, 399.688],
								[552.277, 407.488],
								[576.677, 418.988],
								[599.877, 455.388],
								[580.977, 489.488],
								[599.877, 526.788],
								[578.377, 566.788],
								[599.877, 606.788],
								[578.377, 647.688],
								[599.877, 687.688],
								[578.377, 727.688],
								[599.877, 767.688],
								[551.877, 815.688],
								[391.877, 815.688],
								[343.877, 767.688],
								[365.337, 727.688],
								[343.877, 687.688],
								[365.337, 647.688],
								[343.877, 606.788],
								[365.337, 566.788],
								[343.877, 526.788],
								[362.747, 489.488],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 2",
				},
				{
					inv: false,
					mode: "f",
					pt: {
						a: 0,
						k: {
							i: [
								[-8.8, 0],
								[0, 0],
								[0, 8.8],
								[8.8, 0],
								[0, 0],
								[0, -8.8],
							],
							o: [
								[0, 0],
								[8.8, 0],
								[0, -8.8],
								[0, 0],
								[-8.8, 0],
								[0, 8.8],
							],
							v: [
								[423.877, 559.688],
								[519.877, 559.688],
								[535.877, 543.688],
								[519.877, 527.688],
								[423.877, 527.688],
								[407.877, 543.688],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 3",
				},
				{
					inv: false,
					mode: "f",
					pt: {
						a: 0,
						k: {
							i: [
								[0, 0],
								[0, -8.8],
								[-8.8, 0],
								[0, 0],
								[0, 8.8],
								[8.8, 0],
							],
							o: [
								[-8.8, 0],
								[0, 8.8],
								[0, 0],
								[8.8, 0],
								[0, -8.8],
								[0, 0],
							],
							v: [
								[423.877, 655.688],
								[407.877, 671.688],
								[423.877, 687.688],
								[519.877, 687.688],
								[535.877, 671.688],
								[519.877, 655.688],
							],
							c: true,
						},
						ix: 1,
					},
					o: { a: 0, k: 100, ix: 3 },
					x: { a: 0, k: 0, ix: 4 },
					nm: "Mask 4",
				},
			],
			sw: 1920,
			sh: 1920,
			sc: "#ffffff",
			ip: 0,
			op: 70.0000028511585,
			st: 0,
			bm: 0,
		},
	],
	markers: [],
};
