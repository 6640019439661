import React, { Ref, useEffect, useRef } from "react";
import Animations from "./Animations";

const Home = () => {
	const firstSloganRef: Ref<HTMLHeadingElement> | undefined = useRef(null);
	const secondSloganRef: Ref<HTMLHeadingElement> | undefined = useRef(null);
	const thirdSloganRef: Ref<HTMLHeadingElement> | undefined = useRef(null);

	const foregroundImageRef = useRef<HTMLImageElement>(null);
	const backgroundImageRef = useRef<HTMLImageElement>(null);
	const firstSectionRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const slogans = [
			firstSloganRef.current,
			secondSloganRef.current,
			thirdSloganRef.current,
		] as HTMLHeadingElement[];
		const originalSloganTexts = slogans.map(
			(slogan) => (slogan || { innerText: "" }).innerText
		);

		let current = 1;
		const totalLength = originalSloganTexts.reduce(
			(acc, curr) => acc + curr.length,
			0
		);

		const getCurrentSloganIndex = () => {
			let tmp = 0;
			for (let i = 0; i < slogans.length; i++) {
				if (current > tmp && tmp + originalSloganTexts[i].length >= current) {
					return [i, current - tmp];
				}
				tmp += originalSloganTexts[i].length;
			}
			return [-1, -1];
		};

		slogans.forEach((slogan) => (slogan ? (slogan.innerText = " ") : 0));

		const interval: any = setInterval(() => {
			if (current > totalLength) {
				const target = slogans[slogans.length - 1];
				target.style.borderRight = "2px solid grey";
				let isVisible = false;
				const cursorInterval = setInterval(() => {
					isVisible = !isVisible;
					target.style.borderRight = `2px solid ${
						isVisible ? "white" : "grey"
					}`;
				}, 500);
				setTimeout(() => {
					clearTimeout(cursorInterval);
					target.style.borderRight = "2px solid transparent";
				}, 5000);
				return clearInterval(interval);
			}
			const [sloganIndex, characterIndex] = getCurrentSloganIndex();
			if (sloganIndex > -1) {
				const target = slogans[sloganIndex];
				if (characterIndex === 1 && sloganIndex > 0)
					slogans[sloganIndex - 1].style.borderRight = "none";
				target.innerText = originalSloganTexts[sloganIndex].slice(
					0,
					characterIndex
				);
				target.style.borderRight = "2px solid white";
			}

			current++;
		}, 150);

		const backgroundImage = backgroundImageRef.current;
		const firstSection = firstSectionRef.current;
		const foregroundImage = foregroundImageRef.current;

		if (backgroundImage && firstSection && foregroundImage) {
			window.addEventListener("scroll", () => {
				if (window.scrollY === 0) return;
				const currentScroll = window.scrollY;
				const WINDOW_HEIGHT = 3000;
				if (window.scrollY > WINDOW_HEIGHT)
					firstSection.style.top = `-${window.scrollY - WINDOW_HEIGHT}px`;
				else {
					firstSection.style.top = `0`;
					const sloganHighlightPositions = [750, 1500, 2250];
					// const TRANSITION_ZONE = 60;
					slogans.forEach((_, i) => {
						const targetPosition = sloganHighlightPositions[i];
						if (
							currentScroll > targetPosition &&
							currentScroll < targetPosition + 700
						) {
							// if (currentScroll - targetPosition < TRANSITION_ZONE) {
							// 	console.log(
							// 		`rgba(0 176 240, 0.${Math.round(
							// 			(TRANSITION_ZONE / 100) * (currentScroll - targetPosition)
							// 		)})`
							// 	);
							// 	slogans[i].style.color = `rgba(0, 176, 240, 0.${Math.round(
							// 		(TRANSITION_ZONE / 100) * (currentScroll - targetPosition)
							// 	)})`;
							// } else {
							slogans[i].style.color = "rgb(0, 176, 240)";
							// }
						} else {
							slogans[i].style.color = "white";
						}
					});
				}
				backgroundImage.style.transform = `scale(${1 + currentScroll / 10000})`;
				foregroundImage.style.transform = `scale(${1 + currentScroll / 30000})`;
			});
		}
	}, []);
	return (
		<main>
			<section
				ref={firstSectionRef}
				// style={{ height: "2500px" }}
				className="no-sb z-40 fixed top-0 bg-black w-full h-full overflow-y-hidden"
			>
				<div className="h-screen p-8 md:p-24 lg:p-32 flex flex-col md:flex-row w-full overflow-y-hidden overflow-x-hidden">
					<div className="grid md:grid-rows-3 h-full max-w-md w-full z-10 ">
						<h1 className="font-bold md:text-5xl text-4xl md:text-left text-center text-white">
							<span className="text-brand-primary">Omega</span>
							<span>Recycle</span>
						</h1>
						<div className="lg:text-8xl text-center md:text-left w-full text-6xl flex flex-col justify-center text-white font-bold whitespace-pre ">
							<h1
								className="w-fit bg-black transition-all duration-500"
								ref={firstSloganRef}
							>
								Reduce.
							</h1>
							<h1
								className="w-fit bg-black transition-all duration-500"
								ref={secondSloganRef}
							>
								Reuse.
							</h1>
							<h1
								className="w-fit bg-black duration-500 transition-all"
								ref={thirdSloganRef}
							>
								Recycle.
							</h1>
						</div>
					</div>
					<div className="h-full flex items-center w-full justify-center">
						<div className="relative w-full h-full flex items-center justify-center">
							<img
								ref={backgroundImageRef}
								className="object-contain absolute"
								src="background.png"
								alt="Product"
							/>
							<img
								ref={foregroundImageRef}
								className="object-contain absolute"
								src="foreground.png"
								alt="Product"
							/>
						</div>
					</div>
				</div>
			</section>
			<section
				style={{ marginTop: "calc(3000px + 100vh)" }}
				className=" text-white relative flex justify-center"
			>
				<div className="p-8 md:p-24 xl:p-32 w-full transition-all">
					<p className="text-white text-6xl">For a more sustainable future</p>
					<div className="flex gap-8 mt-24 justify-between flex-col md:flex-row w-full">
						{[
							{
								type: "reduce",
								title: "Reduce",
								text: "Reducing carbon emissions is the most important thing we can do to combat global warming. By reducing our reliance on fossil fuels and investing in clean energy, we can dramatically reduce our carbon emissions and help slow the effects of global warming.",
							},
							{
								type: "reuse",
								title: "Reuse",
								text: "Some advantages of reusing items in relation to the environment are that it can reduce the amount of waste that is sent to landfills, it can conserve resources such as water and energy, and it can reduce pollution.",
							},
							{
								type: "recycle",
								title: "Recycle",
								text: "Recycling conserves natural resources and reduces pollution and greenhouse gas emissions, which helps sustain the environment for future generations to come. ",
							},
						].map(
							(
								item: {
									type: "reduce" | "reuse" | "recycle";
									title: string;
									text: string;
								},
								i
							) => (
								<div
									key={i}
									className="flex md:justify-center gap-8 flex-col w-full md:w-fit"
								>
									<div className="rounded flex justify-center">
										<div className="w-40 h-40 bg-slate-900 ">
											<Animations type={item.type} />
										</div>
									</div>
									<div className="flex flex-col gap-2">
										<h1 className="text-4xl text-white">{item.title}</h1>
										<p className="text-lg text-gray-400">{item.text}</p>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://www.eda.admin.ch/aboutswitzerland/en/home/umwelt/natur/recycling.html"
											className=" text-brand-primary rounded w-fit flex hover:bg-white hover:px-4 transition-all"
										>
											Learn more
										</a>
									</div>
								</div>
							)
						)}
					</div>
				</div>
			</section>
		</main>
	);
};

export default Home;
