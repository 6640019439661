import React from "react";
import Lottie from "react-lottie";
import recycle from "./utils/animations/recycle";

const Animations = ({ type }: { type: "reduce" | "reuse" | "recycle" }) => {
	if (type === "reduce")
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				preserveAspectRatio="xMidYMid meet"
				width="1920"
				height="1920"
				viewBox="0 0 1920 1920"
				style={{ width: "100%", height: "100%" }}
			>
				<defs>
					<animateMotion
						repeatCount="indefinite"
						dur="12.5458792s"
						begin="0s"
						xlinkHref="#_R_G_L_3_G"
						fill="freeze"
						keyTimes="0;0.8643617;0.9308511;1"
						path="M960 2007 C960,2007 960,2007 960,2007 C960,1832.5 960,1134.5 960,960 C960,960 960,960 960,960 "
						keyPoints="0;0;1;1"
						keySplines="0.167 0.167 0.667 1;0.167 0.167 0.667 1;0 0 0 0"
						calcMode="spline"
					/>
					<animateTransform
						repeatCount="indefinite"
						dur="12.5458792s"
						begin="0s"
						xlinkHref="#_R_G_L_3_G"
						fill="freeze"
						attributeName="transform"
						from="-960 -960"
						to="-960 -960"
						type="translate"
						additive="sum"
						keyTimes="0;1"
						values="-960 -960;-960 -960"
						keySplines="0 0 1 1"
						calcMode="spline"
					/>
					<animateMotion
						repeatCount="indefinite"
						dur="12.5458792s"
						begin="0s"
						xlinkHref="#_R_G_L_2_G"
						fill="freeze"
						keyTimes="0;0.3643617;0.4414893;0.8776595;0.9308511;1"
						path="M960 2711 C960,2711 960,2711 960,2711 C960,2535.17 960,1947.83 960,1656 C960,1643.77 960,1665.91 960,1656 C960,1460.93 960,966.49 960,960 C960,960 960,960 960,960 "
						keyPoints="0;0;0.6;0.61;1;1"
						keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.167 0.167 0.833 0.833;0.167 0.167 0.667 1;0 0 0 0"
						calcMode="spline"
					/>
					<animateTransform
						repeatCount="indefinite"
						dur="12.5458792s"
						begin="0s"
						xlinkHref="#_R_G_L_2_G"
						fill="freeze"
						attributeName="transform"
						from="-960 -960"
						to="-960 -960"
						type="translate"
						additive="sum"
						keyTimes="0;1"
						values="-960 -960;-960 -960"
						keySplines="0 0 1 1"
						calcMode="spline"
					/>
					<animateMotion
						repeatCount="indefinite"
						dur="12.5458792s"
						begin="0s"
						xlinkHref="#_R_G_L_1_G"
						fill="freeze"
						keyTimes="0;0.393617;0.4414893;0.5079787;0.5718085;1"
						path="M960 960 C960,960 960,960 960,960 C960,880.83 960,564.17 960,485 C960,485 960,485 960,485 C960,272.67 960,-576.67 960,-789 C960,-789 960,-789 960,-789 "
						keyPoints="0;0;0.27;0.27;1;1"
						keySplines="0.167 0.167 0.667 1;0.167 0.167 0.667 1;0.167 0.167 0.667 0.667;0.167 0 0.833 0.833;0 0 0 0"
						calcMode="spline"
					/>
					<animateTransform
						repeatCount="indefinite"
						dur="12.5458792s"
						begin="0s"
						xlinkHref="#_R_G_L_1_G"
						fill="freeze"
						attributeName="transform"
						from="-960 -960"
						to="-960 -960"
						type="translate"
						additive="sum"
						keyTimes="0;1"
						values="-960 -960;-960 -960"
						keySplines="0 0 1 1"
						calcMode="spline"
					/>
					<animateMotion
						repeatCount="indefinite"
						dur="12.5458792s"
						begin="0s"
						xlinkHref="#_R_G_L_0_G"
						fill="freeze"
						keyTimes="0;0.0930851;1"
						path="M960 960 C960,700 960,-340 960,-600 C960,-600 960,-600 960,-600 "
						keyPoints="0;1;1"
						keySplines="0.333 0 0.833 0.833;0 0 0 0"
						calcMode="spline"
					/>
					<animateTransform
						repeatCount="indefinite"
						dur="12.5458792s"
						begin="0s"
						xlinkHref="#_R_G_L_0_G"
						fill="freeze"
						attributeName="transform"
						from="-960 -960"
						to="-960 -960"
						type="translate"
						additive="sum"
						keyTimes="0;1"
						values="-960 -960;-960 -960"
						keySplines="0 0 1 1"
						calcMode="spline"
					/>
					<animate
						attributeType="XML"
						attributeName="opacity"
						dur="13s"
						from="0"
						to="1"
						xlinkHref="#time_group"
					/>
				</defs>
				<g id="_R_G">
					<g id="_R_G_L_3_G">
						<g
							id="_R_G_L_3_G_L_5_G"
							transform=" translate(1140, 1348) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_3_G_L_5_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="18"
								strokeOpacity="1"
								d=" M-285.55 -386 C-285.55,-386 -285.55,-239.14 -285.55,-239.14 C-285.55,-239.14 -752,-239.14 -752,-239.14 C-752,-239.14 -752,-386 -752,-386 C-752,-386 -285.55,-386 -285.55,-386z "
							/>
						</g>
						<g
							id="_R_G_L_3_G_L_4_G"
							transform=" translate(1140, 1574) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_3_G_L_4_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="22"
								strokeOpacity="1"
								d=" M-285.55 -368 C-285.55,-368 -285.55,-257.14 -285.55,-257.14 C-285.55,-247.21 -293.61,-239.14 -303.55,-239.14 C-303.55,-239.14 -734,-239.14 -734,-239.14 C-743.93,-239.14 -752,-247.21 -752,-257.14 C-752,-257.14 -752,-368 -752,-368 C-752,-377.93 -743.93,-386 -734,-386 C-734,-386 -303.55,-386 -303.55,-386 C-293.61,-386 -285.55,-377.93 -285.55,-368z "
							/>
						</g>
						<g
							id="_R_G_L_3_G_L_3_G"
							transform=" translate(1140, 1814) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_3_G_L_3_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="27"
								strokeOpacity="1"
								d=" M-285.55 -337 C-285.55,-337 -285.55,-288.14 -285.55,-288.14 C-285.55,-261.1 -307.5,-239.14 -334.55,-239.14 C-334.55,-239.14 -703,-239.14 -703,-239.14 C-730.04,-239.14 -752,-261.1 -752,-288.14 C-752,-288.14 -752,-337 -752,-337 C-752,-364.04 -730.04,-386 -703,-386 C-703,-386 -334.55,-386 -334.55,-386 C-307.5,-386 -285.55,-364.04 -285.55,-337z "
							/>
						</g>
						<g
							id="_R_G_L_3_G_L_2_G"
							transform=" translate(1843, 1814) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_3_G_L_2_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="24"
								strokeOpacity="1"
								d=" M-285.55 -374 C-285.55,-374 -285.55,-251.14 -285.55,-251.14 C-285.55,-244.52 -290.92,-239.14 -297.55,-239.14 C-297.55,-239.14 -740,-239.14 -740,-239.14 C-746.62,-239.14 -752,-244.52 -752,-251.14 C-752,-251.14 -752,-374 -752,-374 C-752,-380.62 -746.62,-386 -740,-386 C-740,-386 -297.55,-386 -297.55,-386 C-290.92,-386 -285.55,-380.62 -285.55,-374z "
							/>
						</g>
						<g
							id="_R_G_L_3_G_L_1_G"
							transform=" translate(1843, 1575) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_3_G_L_1_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="25"
								strokeOpacity="1"
								d=" M-285.55 -335 C-285.55,-335 -285.55,-290.14 -285.55,-290.14 C-285.55,-261.99 -308.4,-239.14 -336.55,-239.14 C-336.55,-239.14 -701,-239.14 -701,-239.14 C-729.15,-239.14 -752,-261.99 -752,-290.14 C-752,-290.14 -752,-335 -752,-335 C-752,-363.15 -729.15,-386 -701,-386 C-701,-386 -336.55,-386 -336.55,-386 C-308.4,-386 -285.55,-363.15 -285.55,-335z "
							/>
						</g>
						<g
							id="_R_G_L_3_G_L_0_G"
							transform=" translate(1843, 1343) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_3_G_L_0_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="18"
								strokeOpacity="1"
								d=" M-285.55 -329 C-285.55,-329 -285.55,-296.14 -285.55,-296.14 C-285.55,-264.68 -311.09,-239.14 -342.55,-239.14 C-342.55,-239.14 -695,-239.14 -695,-239.14 C-726.46,-239.14 -752,-264.68 -752,-296.14 C-752,-296.14 -752,-329 -752,-329 C-752,-360.46 -726.46,-386 -695,-386 C-695,-386 -342.55,-386 -342.55,-386 C-311.09,-386 -285.55,-360.46 -285.55,-329z "
							/>
						</g>
					</g>
					<g id="_R_G_L_2_G">
						<g
							id="_R_G_L_2_G_L_4_G"
							transform=" translate(1080, 960) translate(0, 0)"
						/>
						<g
							id="_R_G_L_2_G_L_3_G"
							transform=" translate(1140, 893) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_2_G_L_3_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="17"
								strokeOpacity="1"
								d=" M-285.55 -361 C-285.55,-361 -285.55,-264.14 -285.55,-264.14 C-285.55,-250.34 -296.75,-239.14 -310.55,-239.14 C-310.55,-239.14 -727,-239.14 -727,-239.14 C-740.8,-239.14 -752,-250.34 -752,-264.14 C-752,-264.14 -752,-361 -752,-361 C-752,-374.8 -740.8,-386 -727,-386 C-727,-386 -310.55,-386 -310.55,-386 C-296.75,-386 -285.55,-374.8 -285.55,-361z "
							/>
						</g>
						<g
							id="_R_G_L_2_G_L_2_G"
							transform=" translate(1140, 1123) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_2_G_L_2_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="28"
								strokeOpacity="1"
								d=" M-285.55 -326 C-285.55,-326 -285.55,-299.14 -285.55,-299.14 C-285.55,-266.03 -312.43,-239.14 -345.55,-239.14 C-345.55,-239.14 -692,-239.14 -692,-239.14 C-725.11,-239.14 -752,-266.03 -752,-299.14 C-752,-299.14 -752,-326 -752,-326 C-752,-359.11 -725.11,-386 -692,-386 C-692,-386 -345.55,-386 -345.55,-386 C-312.43,-386 -285.55,-359.11 -285.55,-326z "
							/>
						</g>
						<g
							id="_R_G_L_2_G_L_1_G"
							transform=" translate(1843, 1118) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_2_G_L_1_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="18"
								strokeOpacity="1"
								d=" M-285.55 -374 C-285.55,-374 -285.55,-251.14 -285.55,-251.14 C-285.55,-244.52 -290.92,-239.14 -297.55,-239.14 C-297.55,-239.14 -740,-239.14 -740,-239.14 C-746.62,-239.14 -752,-244.52 -752,-251.14 C-752,-251.14 -752,-374 -752,-374 C-752,-380.62 -746.62,-386 -740,-386 C-740,-386 -297.55,-386 -297.55,-386 C-290.92,-386 -285.55,-380.62 -285.55,-374z "
							/>
						</g>
						<g
							id="_R_G_L_2_G_L_0_G"
							transform=" translate(1843, 889) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_2_G_L_0_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="28"
								strokeOpacity="1"
								d=" M-285.55 -346 C-285.55,-346 -285.55,-279.14 -285.55,-279.14 C-285.55,-257.06 -303.47,-239.14 -325.55,-239.14 C-325.55,-239.14 -712,-239.14 -712,-239.14 C-734.08,-239.14 -752,-257.06 -752,-279.14 C-752,-279.14 -752,-346 -752,-346 C-752,-368.08 -734.08,-386 -712,-386 C-712,-386 -325.55,-386 -325.55,-386 C-303.47,-386 -285.55,-368.08 -285.55,-346z "
							/>
						</g>
					</g>
					<g id="_R_G_L_1_G">
						<g
							id="_R_G_L_1_G_L_5_G"
							transform=" translate(1140, 1348) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_1_G_L_5_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="18"
								strokeOpacity="1"
								d=" M-285.55 -386 C-285.55,-386 -285.55,-239.14 -285.55,-239.14 C-285.55,-239.14 -752,-239.14 -752,-239.14 C-752,-239.14 -752,-386 -752,-386 C-752,-386 -285.55,-386 -285.55,-386z "
							/>
						</g>
						<g
							id="_R_G_L_1_G_L_4_G"
							transform=" translate(1140, 1574) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_1_G_L_4_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="22"
								strokeOpacity="1"
								d=" M-285.55 -368 C-285.55,-368 -285.55,-257.14 -285.55,-257.14 C-285.55,-247.21 -293.61,-239.14 -303.55,-239.14 C-303.55,-239.14 -734,-239.14 -734,-239.14 C-743.93,-239.14 -752,-247.21 -752,-257.14 C-752,-257.14 -752,-368 -752,-368 C-752,-377.93 -743.93,-386 -734,-386 C-734,-386 -303.55,-386 -303.55,-386 C-293.61,-386 -285.55,-377.93 -285.55,-368z "
							/>
						</g>
						<g
							id="_R_G_L_1_G_L_3_G"
							transform=" translate(1140, 1814) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_1_G_L_3_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="27"
								strokeOpacity="1"
								d=" M-285.55 -337 C-285.55,-337 -285.55,-288.14 -285.55,-288.14 C-285.55,-261.1 -307.5,-239.14 -334.55,-239.14 C-334.55,-239.14 -703,-239.14 -703,-239.14 C-730.04,-239.14 -752,-261.1 -752,-288.14 C-752,-288.14 -752,-337 -752,-337 C-752,-364.04 -730.04,-386 -703,-386 C-703,-386 -334.55,-386 -334.55,-386 C-307.5,-386 -285.55,-364.04 -285.55,-337z "
							/>
						</g>
						<g
							id="_R_G_L_1_G_L_2_G"
							transform=" translate(1843, 1814) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_1_G_L_2_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="24"
								strokeOpacity="1"
								d=" M-285.55 -374 C-285.55,-374 -285.55,-251.14 -285.55,-251.14 C-285.55,-244.52 -290.92,-239.14 -297.55,-239.14 C-297.55,-239.14 -740,-239.14 -740,-239.14 C-746.62,-239.14 -752,-244.52 -752,-251.14 C-752,-251.14 -752,-374 -752,-374 C-752,-380.62 -746.62,-386 -740,-386 C-740,-386 -297.55,-386 -297.55,-386 C-290.92,-386 -285.55,-380.62 -285.55,-374z "
							/>
						</g>
						<g
							id="_R_G_L_1_G_L_1_G"
							transform=" translate(1843, 1575) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_1_G_L_1_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="25"
								strokeOpacity="1"
								d=" M-285.55 -335 C-285.55,-335 -285.55,-290.14 -285.55,-290.14 C-285.55,-261.99 -308.4,-239.14 -336.55,-239.14 C-336.55,-239.14 -701,-239.14 -701,-239.14 C-729.15,-239.14 -752,-261.99 -752,-290.14 C-752,-290.14 -752,-335 -752,-335 C-752,-363.15 -729.15,-386 -701,-386 C-701,-386 -336.55,-386 -336.55,-386 C-308.4,-386 -285.55,-363.15 -285.55,-335z "
							/>
						</g>
						<g
							id="_R_G_L_1_G_L_0_G"
							transform=" translate(1843, 1343) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_1_G_L_0_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="18"
								strokeOpacity="1"
								d=" M-285.55 -329 C-285.55,-329 -285.55,-296.14 -285.55,-296.14 C-285.55,-264.68 -311.09,-239.14 -342.55,-239.14 C-342.55,-239.14 -695,-239.14 -695,-239.14 C-726.46,-239.14 -752,-264.68 -752,-296.14 C-752,-296.14 -752,-329 -752,-329 C-752,-360.46 -726.46,-386 -695,-386 C-695,-386 -342.55,-386 -342.55,-386 C-311.09,-386 -285.55,-360.46 -285.55,-329z "
							/>
						</g>
					</g>
					<g id="_R_G_L_0_G">
						<g
							id="_R_G_L_0_G_L_4_G"
							transform=" translate(1080, 960) translate(0, 0)"
						/>
						<g
							id="_R_G_L_0_G_L_3_G"
							transform=" translate(1140, 893) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_0_G_L_3_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="17"
								strokeOpacity="1"
								d=" M-285.55 -361 C-285.55,-361 -285.55,-264.14 -285.55,-264.14 C-285.55,-250.34 -296.75,-239.14 -310.55,-239.14 C-310.55,-239.14 -727,-239.14 -727,-239.14 C-740.8,-239.14 -752,-250.34 -752,-264.14 C-752,-264.14 -752,-361 -752,-361 C-752,-374.8 -740.8,-386 -727,-386 C-727,-386 -310.55,-386 -310.55,-386 C-296.75,-386 -285.55,-374.8 -285.55,-361z "
							/>
						</g>
						<g
							id="_R_G_L_0_G_L_2_G"
							transform=" translate(1140, 1123) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_0_G_L_2_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="28"
								strokeOpacity="1"
								d=" M-285.55 -326 C-285.55,-326 -285.55,-299.14 -285.55,-299.14 C-285.55,-266.03 -312.43,-239.14 -345.55,-239.14 C-345.55,-239.14 -692,-239.14 -692,-239.14 C-725.11,-239.14 -752,-266.03 -752,-299.14 C-752,-299.14 -752,-326 -752,-326 C-752,-359.11 -725.11,-386 -692,-386 C-692,-386 -345.55,-386 -345.55,-386 C-312.43,-386 -285.55,-359.11 -285.55,-326z "
							/>
						</g>
						<g
							id="_R_G_L_0_G_L_1_G"
							transform=" translate(1843, 1118) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_0_G_L_1_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="18"
								strokeOpacity="1"
								d=" M-285.55 -374 C-285.55,-374 -285.55,-251.14 -285.55,-251.14 C-285.55,-244.52 -290.92,-239.14 -297.55,-239.14 C-297.55,-239.14 -740,-239.14 -740,-239.14 C-746.62,-239.14 -752,-244.52 -752,-251.14 C-752,-251.14 -752,-374 -752,-374 C-752,-380.62 -746.62,-386 -740,-386 C-740,-386 -297.55,-386 -297.55,-386 C-290.92,-386 -285.55,-380.62 -285.55,-374z "
							/>
						</g>
						<g
							id="_R_G_L_0_G_L_0_G"
							transform=" translate(1843, 889) scale(1, 0.93309) translate(0, 0)"
						>
							<path
								id="_R_G_L_0_G_L_0_G_D_0_P_0"
								stroke="#ffffff"
								strokeLinecap="round"
								strokeLinejoin="round"
								fill="none"
								strokeWidth="28"
								strokeOpacity="1"
								d=" M-285.55 -346 C-285.55,-346 -285.55,-279.14 -285.55,-279.14 C-285.55,-257.06 -303.47,-239.14 -325.55,-239.14 C-325.55,-239.14 -712,-239.14 -712,-239.14 C-734.08,-239.14 -752,-257.06 -752,-279.14 C-752,-279.14 -752,-346 -752,-346 C-752,-368.08 -734.08,-386 -712,-386 C-712,-386 -325.55,-386 -325.55,-386 C-303.47,-386 -285.55,-368.08 -285.55,-346z "
							/>
						</g>
					</g>
				</g>
				<g id="time_group" />
			</svg>
		);
	else if (type === "reuse")
		return (
			<div className="p-4 h-full">
				<div className="border-2 border-white h-full rounded p-2 svg-elem">
					<p className="text-sm reduce-elem">
						Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores,
						necessitatibus.
					</p>
				</div>
			</div>
		);
	else if (type === "recycle") {
		return (
			<Lottie
				width={160}
				height={160}
				options={{
					animationData: recycle,
					loop: true,
					autoplay: true,
				}}
			/>
		);
	}
	return <div></div>;
};

export default Animations;
